.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1300px) {
  .home {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.home_split {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  width: 100%;
}

.home_split_left {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  padding-bottom: 5vh;
  padding-right: 4vw;
  min-height: 80vh;
  z-index: 999999;
}
.home_split_left_title {
  font-size: 60px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.home_split_left_title_left {
  font-size: 36px;
  font-weight: 500;
  width: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.home_split_left_title_right {
  font-size: 36px;
  font-weight: 500;
  width: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.home_split_left_title_right_active,
.home_split_left_title_left_active {
  font-size: 36px;
  font-weight: 700;
  width: 50%;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.home_split_left_title_inputbox {
  width: 350px;
  margin-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.home_split_left_title_inputbox_filled {
  margin-top: -12px;
}

.home_split_left_title_inputbox_input {
  width: calc(100% - 50px);
  height: 50px;
  border: 2px solid white;
  background-color: black;
  color: white;
  padding-left: 12px;
  margin-right: -1px;
}

.home_split_left_title_inputbox_input::placeholder {
  color: white;
  font-size: 12px;
}

.home_split_left_title_inputbox_enter {
  height: 50px;
  background-color: white;
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
}

.home_split_left_title_inputbox_enter_icon {
  width: 30px;
  height: 30px;
}

.home_split_left_text {
  padding-top: 33px;
  font-size: 24px;
  padding-bottom: 33px;
}

.home_split_left_images_row_images {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.home_split_left_images_row_images_imgbox {
  height: 100px;
}

.home_split_left_images_row_images_delete {
  position: relative;
  margin-top: -100px;
  margin-left: 80%;
  cursor: pointer;
  background-color: #ffffff5b;
  border-radius: 99999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home_split_left_images_row_images_img {
  width: auto;
  height: 100px;
  object-fit: contain;
}

.home_split_left_buttons {
  padding-top: 73px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 33px;
  flex-wrap: wrap;
}

.home_split_left_buttons_row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 18px;
}

.home_split_left_buttons_row_col {
  padding-right: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 33px;
}

.home_split_left_form {
  width: 100%;
}

.home_split_left_form_meet {
  width: 100%;
}

.home_split_left_form_meet_title {
  font-size: 36px;
  padding-top: 10px;
}

.home_split_left_form_meet_inputbox {
  padding-top: 20px;
}

.home_split_left_form_meet_inputbox_text {
  font-size: 12px;
  padding-bottom: 8px;
}

.home_split_left_form_meet_inputbox_input {
  border: 1px solid white;
  background-color: black;
  color: white;
  height: 50px;
  width: 70%;
  max-width: 350px;
  padding-left: 17px;
}

.home_split_left_form_profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 36px;
}

.home_split_left_form_profile_col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 24px;
  margin-top: 50px;
}

.home_split_left_form_nextbtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 29px;
}

.home_split_left_chat {
  width: 100%;
}

.home_split_left_chat_chatboxme {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.home_split_left_chat_chatboxme_left {
}

.home_split_left_chat_chatboxme_left_icon {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  object-fit: cover;
  border: 1px solid white;
}

.home_split_left_chat_chatboxme_middle {
  max-width: 70%;
}

.home_split_left_chat_chatboxme_middle_top {
  font-size: 12px;
  padding-left: 12px;
  padding-top: 14px;
  padding-bottom: 12px;
}

.home_split_left_chat_chatboxme_middle_bottom {
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.home_split_left_chat_chatboxme_middle_bottom_input {
  width: 250px;
  height: 50px;
  border: 2px solid white;
  background-color: black;
  color: white;
  padding-left: 12px;
}
.home_split_left_chat_chatboxme_middle_bottom_input::placeholder {
  color: grey;
  font-size: 12px;
}

.home_split_left_chat_chatboxme_right {
}

.home_split_left_chat_chatboxavatar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 12px;
  padding-bottom: 12px;
}

.home_split_left_chat_chatboxavatar_left {
}

.home_split_left_chat_chatboxavatar_left_icon {
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  object-fit: cover;
  border: 1px solid white;
}

.home_split_left_chat_chatboxavatar_middle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 70%;
}

.home_split_left_chat_chatboxavatar_middle_top {
  font-size: 12px;
  padding-right: 12px;
  padding-top: 14px;
  padding-bottom: 12px;
}

.home_split_left_chat_chatboxavatar_middle_bottom {
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  padding-top: 10px;
  padding-bottom: 12px;
  margin-right: 12px;
}

.home_split_left_chat_chatboxavatar_middle_bottom_input {
  width: 250px;
  height: 50px;
  border: 2px solid white;
  background-color: black;
  color: white;
  padding-left: 12px;
}
.home_split_left_chat_chatboxavatar_middle_bottom_input::placeholder {
  color: grey;
  font-size: 12px;
}

.home_split_right {
  width: 45%;
  height: 100%;
}

.home_split_right_background {
  height: calc(100vh);
  width: auto;
  top: 0;
  position: fixed;
  z-index: -9999;
}
@media only screen and (min-width: 1100px) {
  .home_split_right_backgroundFull {
    height: calc(100vh);
    position: fixed;
    width: auto;
    max-width: 70vw;
    object-fit: cover;
    top: 0;
    right: 0;
    z-index: -9999;
  }
}
.home_split_right_backgroundFull {
  height: calc(100vh);
  position: fixed;
  width: auto;
  max-width: 70vw;
  object-fit: cover;
  top: 0;
  right: -250;
  z-index: -9999;
}

.home_split_right_cards {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}
.home_split_right_cards_card {
  width: calc(33.3% - 16px);
  height: 173px;
  cursor: pointer;
  position: relative; /* Ensure the parent is positioned for the overlay to align properly */
}

.home_split_right_cards_card_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.home_split_right_cards_card_overlay {
  display: none; /* Hide overlay by default */
  position: absolute; /* Position overlay absolutely within the parent */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(149, 0, 120, 0.2); /* Semi-transparent background */
  z-index: 1; /* Ensure it appears above the image */
}

.home_split_right_cards_card:hover .home_split_right_cards_card_overlay {
  display: flex; /* Show overlay on hover */
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 16px;
  z-index: 9999; /* Bring it to the top */
  border: 1px solid white;
}

.home_split_right_cards_card_overlay_text {
}
