.camera_div {
  width: 40vw;
  height: 80vh;
  position: fixed;
  top: 100px;
}

.camera {
  text-align: center;
}

.camera-container video {
  width: 100%;
}

.camera_div_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.camera_div_bottom_takePhoto {
  width: 60px;
  height: 60px;
  padding-left: 6px;
  padding-top: 6px;
  background-color: white;
  border-radius: 99999px;
  cursor: pointer;
}

.camera_div_bottom_takePhoto_disabled {
  width: 60px;
  height: 60px;
  padding-left: 6px;
  padding-top: 6px;
  background-color: grey;
  border-radius: 99999px;
  cursor: not-allowed;
}

.camera_div_bottom_takePhoto:hover {
  background-color: grey;
}

.camera_div_bottom_takePhoto_inner {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: white;
  border-radius: 99999px;
  border: 2px solid #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.camera_div_bottom_takePhoto_inner_disabled {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: grey;
  border-radius: 99999px;
  border: 2px solid #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.camera_div_bottom_takePhoto_inner:hover {
  background-color: grey;
}

.result {
  display: none;
  position: relative;
  width: 100%;
  height: 300px; /* Adjust the height as needed */
}

.result.hasPhoto {
  display: block;
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
  z-index: -99999;
  position: relative;
}

.blackbox {
  margin-top: -40px;
  margin-left: -10px;
  width: 50px;
  height: 50px;
  z-index: 9999;
  background-color: black;
}
