.Togglebutton {
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 9999px;
  background-color: white;
  color: black;
  font-weight: 700;
  font-size: 20px;
  border: 2px solid #ffffff;
  cursor: pointer;
  white-space: nowrap;
  width: auto;
}

.Togglebutton_disabled {
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 9999px;
  background-color: grey;
  color: black;
  font-weight: 700;
  font-size: 20px;
  border: 2px solid #ffffff;
  cursor: not-allowed;
  white-space: nowrap;
  width: auto;
}

.Togglebutton:hover {
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 9999px;
  background-color: #662482;
  color: white;
  font-weight: 700;
  font-size: 20px;
  border: 2px solid #ffffff;
}

.Togglebutton_active {
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 9999px;
  background-color: #662482;
  color: white;
  font-weight: 700;
  font-size: 20px;
  border: 2px solid #ffffff;
}

.TogglebuttonSmall {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 9999px;
  color: white;
  font-size: 12px;
  border: 1px solid #ffffff;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.TogglebuttonSmall:hover {
  background-color: #662482;
}

.TogglebuttonSmall_active {
  background-color: #662482;

  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 9999px;
  color: white;
  font-size: 12px;
  border: 1px solid #ffffff;
  cursor: pointer;
  white-space: nowrap;
  width: auto;
}
