.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.dropdown_title {
  border-bottom: 1px solid white;
  padding-bottom: 13px;
  padding-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.dropdown_title_left {
  font-size: 16px;
  font-weight: 700;
}

.dropdown_title_right {
}
.dropdown_title_right_icon {
  width: 30px;
  height: 30px;
  padding-top: 10px;
}

.dropdown_body {
  width: 100%;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropdown_body_row {
  width: 100%;
}

.dropdown_body_row_genderbuttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 24px;
}

.dropdown_body_row_genderbuttons_button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 120px;
  height: 20px;
  gap: 12px;
  font-size: 12px;
}

.dropdown_body_row_genderbuttons_button_circle {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  border: 1px solid white;
}

.dropdown_body_row_genderbuttons_button_circle_active {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  border: 1px solid white;
}

.dropdown_body_row_genderbuttons_button_circle_active_innercircle {
  background-color: white;
  width: 10px;
  height: 10px;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 999px;
}

.dropdown_body_row_inputs {
  padding-bottom: 19px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.dropdown_body_row_inputs_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  font-size: 12px;
  width: calc(50% - 60px);
}

.dropdown_body_row_inputs_box_input {
  max-width: 271px;
  width: 100%;
  border: 1px solid white;
  background-color: black;
  height: 50px;
  color: white;
  padding-left: 10px;
}

.dropdown_body_row_radiobox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
}

.dropdown_body_row_radiobox_left {
  font-size: 12px;
  padding-right: 30px;
}

.dropdown_body_row_radiobox_right {
}

.dropdown_body_row_inputs_addButton {
  margin-top: 26px;
  width: 50px;
  height: 50px;
}

.dropdown_body_row_inputs_addButtonWithBorder {
  margin-top: 26px;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown_body_row_inputs_addButtonWithBorder_btn {
  width: 35spx;
  height: 35px;
}

.dropdown_body_row_toptext {
  font-size: 12px;
}

.dropdown_body_row_bottombuttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 22px;
  padding-top: 18px;
}

.home_split_right_nextBtnRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
}
