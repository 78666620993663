.explore {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.explore_title {
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.explore_subtitle {
  padding-top: 33px;
  font-size: 24px;
  padding-bottom: 33px;
  width: 680px;
}

.explore_bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  position: fixed;
  background: linear-gradient(to top, rgb(0, 0, 0), transparent);
}

.explore_bottom_top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 12px;
}

.explore_bottom_top_element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  z-index: 999999;
}

.explore_bottom_top_placeholder {
  height: 30px;
}

.explore_bottom_top_element_top {
  padding-bottom: 8px;
}

.explore_bottom_top_element_middle {
  background-color: white;
  border-radius: 999px;
  width: 15px;
  height: 15px;
}

.explore_bottom_top_element_middle_around {
  border: 1px solid white;
  border-radius: 999px;
  width: 27px;
  height: 27px;
  margin-left: -6px;
  margin-top: -6px;
  display: none;
}

.explore_bottom_top_element_middle_active {
  background-color: #950178;
  border-radius: 999px;
  width: 15px;
  height: 15px;
}

.explore_bottom_top_element_middle_around_active {
  border: 1px solid white;
  border-radius: 999px;
  width: 27px;
  height: 27px;
  margin-left: -6px;
  margin-top: -6px;
}

.explore_bottom_top_element_bottom0 {
  height: 70px;
  background-color: white;
  width: 1px;
  margin-left: 7.5px;
}
.explore_bottom_top_element_bottom1 {
  height: 35px;
  background-color: white;
  width: 1px;
  margin-left: 7.5px;
}
.explore_bottom_top_element_bottom2 {
  height: 100px;
  background-color: white;
  width: 1px;
  margin-left: 7.5px;
}

.explore_bottom_top_element_bottomnone {
  height: 0px;
  background-color: white;
  width: 1px;
  margin-left: 7.5px;
  margin-top: -7.5px;
}

.explore_bottom_top_element:hover .explore_bottom_top_element_middle {
  background-color: #950178; /* Change to the desired color */
}

.explore_bottom_top_element:hover .explore_bottom_top_element_middle_around {
  display: block;
}

.explore_bottom_middle {
  width: 100vw;
  height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -38px;
}

.explore_bottom_middle_line {
  width: 100vw;
  height: 1px;
  background-color: white;
}

.explore_bottom_middle_line_active {
  width: 0px;
  height: 1px;
  background-color: #950178;
  position: absolute;
}

.explore_bottom_middle_icon {
  width: 38px;
  height: 76px;
  position: absolute;
  right: 0;
}

.explore_bottom_bottom {
  height: 50px;
  width: 100%;
}

.explore_bottom_bottom_mediabar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}

.explore_bottom_bottom_mediabar_count {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
  margin-top: -5px;
  font-size: 12px;
}
.explore_bottom_bottom_mediabar_mode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 160px;
}

.explore_bottom_bottom_mediabar_play {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 180px;
}

.explore_bottom_bottom_mediabar_sound {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 130px;
}

.explore_bottom_bottom_mediabar_mode_iconbox {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.explore_bottom_bottom_mediabar_mode_iconbox_icon {
  width: 20px;
  height: 20px;
}

.explore_background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.explore_background_list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 200px;
  gap: 10px;
}

.explore_background_list_element {
  width: 20vw;
  object-fit: cover;
  cursor: pointer;
}

.explore_background_list_element_top {
  width: 100%;
}

.explore_background_list_element_top_img {
  width: 20vw;
  height: 20vw;
  object-fit: cover;
}

.explore_background_list_element_bottom {
  padding-top: 8px;
  padding-bottom: 8px;
}

.explore_background_img {
  height: calc(100vh);
  position: fixed;
  width: auto;
  object-fit: cover;
  top: 0;
  z-index: -9999;
}

.inactive {
  opacity: 0.4;
}

.explore_bottom_bottom_mediabar_videobox {
  width: 20vw;
  max-width: 25vh;
  top: 0;
  margin-top: 30vh;
  right: 0;
  position: fixed;
  height: auto;
  display: block;
}

.explore_bottom_bottom_mediabar_videobox_video {
  width: 100%;
  height: auto;
}

.explore_bottom_bottom_mediabar_videobox_closebtn {
  width: 30px;
  height: 30px;
  border-radius: 999px;
  /*box-shadow: 0px 1px 2px #00000080;
*/
  max-width: 25vh;
  top: 0;
  margin-top: calc(30vh + 5px);
  right: 5px;
  position: fixed;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
