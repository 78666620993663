.audiorecorder {
  padding-top: 12px;
  z-index: 9999999999999;
  position: relative;
}

.audiorecorder_text {
}

.audiorecorder_text_top {
}

.audiorecorder_text_bottom {
}

.audiorecorder_topbar {
  margin-bottom: 24px;
}
.audiorecorder_bottombar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 550px;
}

.audiorecorder_bottombar_left {
}

.audiorecorder_bottombar_middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 33px;
}

.audiorecorder_bottombar_right {
}

.audiorecorder_icon {
  width: 20px;
  height: 24px;
}

.audiorecorder_savebtn {
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-star;
}
