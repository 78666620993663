.mymedia {
  width: 100%;
}

.mymedia_headline {
  font-size: 24px;
  padding-bottom: 18px;
}

.mymedia_toprow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.mymedia_toprow_text {
  font-size: 12px;
  padding-top: 7px;
}

.mymedia_toprow_select {
  background-color: black;
  border-top: none;
  border-bottom: 1px solid white;
  border-left: none;
  border-right: none;
  color: white;
  height: 30px;
  font-size: 12px;
  min-width: 100px;
  cursor: pointer;
}

.mymedia_toprow_iconbox {
  padding-top: 5px;
  cursor: pointer;
}

.mymedia_toprow_iconbox_icon {
  width: 23px;
  height: 23px;
}

.mymedia_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 38px;
  gap: 40px;
}

.mymedia_list_element_top {
  width: 197px;
  height: 181px;
  background-color: #333333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mymedia_list_remove {
  position: relative;
  margin-left: calc(100% - 60px);
  margin-bottom: -40px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: #ffffff5b;
  border-radius: 99999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.mymedia_list_element_top_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mymedia_list_element_bottom {
  padding-top: 8px;
}

/* SORTLIST */

.mymedia_sortlist {
  width: 1005;
}

.mymedia_sortlist_toprow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}

.mymedia_sortlist_toprow_left {
  width: 100px;
}

.mymedia_sortlist_toprow_middle {
  width: 100px;
}

.mymedia_sortlist_toprow_right {
  width: calc(100% - 200px);
}

.mymedia_sortlist_listelement {
  cursor: pointer;
  border-bottom: 1px solid #707070;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  height: 45px;
}

.mymedia_sortlist_listelement:hover {
  background-color: #662481;
}

.mymedia_sortlist_listelement_left {
  width: 100px;
}

.mymedia_sortlist_listelement_middle {
  width: 100px;
}

.mymedia_sortlist_listelement_right {
  width: calc(100% - 260px);
}

.mymedia_sortlist_listelement_draggable {
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
}
