.DesktopTopNavbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-top: 33px;
  padding-bottom: 33px;
}

.DesktopTopNavbar_left {
}

.DesktopTopNavbar_left_logo {
  width: 318px;
  height: 71px;
  cursor: pointer;
}

.DesktopTopNavbar_right {
}

.DesktopTopNavbarright_menubtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  padding: 10px;
}

.DesktopTopNavbarright_menubtn_row {
  width: 50px;
  height: 2px;
  background-color: #ffffff;
}

.DesktopTopNavbar_Sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 521px;
  background-color: #662482;
  overflow-y: scroll;
  padding-bottom: 100px;
  z-index: 9999999999999999999;
}

.DesktopTopNavbar_Sidebar_toprow {
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.DesktopTopNavbar_Sidebar_toprow_closebtn {
  padding-top: 70px;
  cursor: pointer;
  padding-bottom: 30px;
  margin-left: 380px;
}

.DesktopTopNavbar_Sidebar_toprow_closebtn_rowleft {
  width: 50px;
  height: 2px;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  background-color: white;
}

.DesktopTopNavbar_Sidebar_toprow_closebtn_rowright {
  width: 50px;
  height: 2px;
  transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
  background-color: white;
}

.DesktopTopNavbar_Sidebar_headline {
  font-size: 60px;
  padding-left: 55px;
  width: 100%;
  height: 90px;
  font-weight: 700;
  padding-top: 10px;
}
.DesktopTopNavbar_Sidebar_headline:hover {
}

.DesktopTopNavbar_Sidebar_subheadline {
  cursor: pointer;
  font-size: 24px;
  padding-left: 118px;
  width: 100%;
  height: 55px;
  padding-top: 15px;
}

.DesktopTopNavbar_Sidebar_subheadline:hover {
  text-decoration: underline;
}

.DesktopTopNavbar_Sidebar_subsub {
  cursor: pointer;
  font-size: 18px;
  padding-left: 160px;
  width: 100%;
  height: 33px;
  font-weight: 300;
}

.DesktopTopNavbar_Sidebar_subsub:hover {
  text-decoration: underline;
}
