/* Skalierungsfaktoren */

/* Mobil/Tablet */
@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .imprint {
    width: 100%;
    height: 100%;
    background-color: var(--primaryBackground);
    padding-bottom: 150px;
  }

}
/* Desktop*/
@media only screen and (min-width: 1050px) {
  .imprint {
    width: 100%;
    height: 100%;
    background-color: var(--primaryBackground);
    padding-bottom: 150px;
  }
}
